<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div class="intro-wrapper">
    <form class="intro-form" @submit.prevent="create()">
      <h1>Opprett tippelag</h1>
      <div class="form-row">
        <label class="label" for="team-name">Tippelagsnavn</label>
        <input class="input" id="team-name" type="text" v-model="teamName" autocomplete="off" />
      </div>
      <p v-if="!isTyping && formErrorMessage" class="alert" aria-live="assertive">
        {{ formErrorMessage }}
      </p>
      <div class="btn-wrapper">
        <button class="btn" type="submit">Opprett tippelag</button>
      </div>
    </form>

    <p class="u-text-center">
      - Eller -
      <br />
      <router-link to="/team-connect">Koble til tippelag</router-link>
    </p>
    <Spinner v-if="isLoading" />
  </div> -->
</template>

<!-- <script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { Team } from "../../../../shared/models/Team";
import { sleep } from "../../../../shared/utils/sleep";
import { TippelagetErrorMessages } from "../../i18n/tippelagetErrorMessages";
import userStore from "../../store/userStore";
import router from "../../router";
import { fDb } from "../../registerFirebase";

export default defineComponent({
  setup(props) {
    const teamName = ref("");
    const isTyping = ref(false);
    const isLoading = ref(false);
    const formErrorMessage = ref("");
    const { user, addTeamToUser } = userStore();

    watch(teamName, () => {
      if (!teamName.value) return;

      isTyping.value = true;
      formErrorMessage.value = "";
      sleep(1000).then(() => {
        isTyping.value = false;
        validateForm();
      });
    });

    const validateForm = () => {
      if (teamName.value && teamName.value.length < 26) return;
      formErrorMessage.value = TippelagetErrorMessages.teamNameTooLong;
    };

    const create = async () => {
      validateForm();

      if (formErrorMessage.value || isLoading.value) return;

      isLoading.value = true;

      try {
        const teamsRef = fDb.collection("teams");
        const isTeamNameTaken = await teamsRef.where("name", "==", teamName.value).get();
        if (!isTeamNameTaken.empty) throw "name taken";

        const team = new Team({
          name: teamName.value,
          memberIds: [user.value.id]
        });

        const teamDoc = await teamsRef.add(team.toDynamic());
        console.log("team created", teamDoc.id);

        await addTeamToUser(user.value, teamDoc.id);

        teamName.value = "";
        router.push("/profile");
      } catch (error) {
        if (error === "name taken") {
          formErrorMessage.value = TippelagetErrorMessages.teamNameExists;
        } else {
          formErrorMessage.value = TippelagetErrorMessages.teamNameInvalid;
        }
      }
      isLoading.value = false;
    };

    return { teamName, isLoading, isTyping, formErrorMessage, create };
  }
});
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
</style>
